<template>
  <nav-header :title="title"></nav-header>
  <div id="statisticsDiv" class="container">
    <div class="mb-4" id="titleDiv">
      <h4>
        {{ $englishPreferred ? "Project statistics" : "Projektstatistik" }}
      </h4>
      <p>
        {{
          $englishPreferred
            ? "Data on this page is calculated nightly. The data can be up to one day old."
            : "Data fremvist på denne side udregnes hver nat, data kan derfor være op til et døgn bagud."
        }}
      </p>
    </div>
    <div class="mb-4">
      <p>
        {{
          $englishPreferred
            ? "Amount of participants with at least one spontaneous count"
            : "Antal deltagere med mindst en spontantælling"
        }}:<br /><b v-if="generalStatistics[0]">{{
          generalStatistics[0].totalUsersWithCount
        }}</b>
      </p>
    </div>

    <div class="mb-4">
      <p>
        {{
          $englishPreferred
            ? "Amount of participants with at least one spontaneous count in current year"
            : "Antal deltagere med mindst en spontantælling I"
        }}
        {{currentYear}}:<br /><b v-if="generalStatistics[0]">{{
          generalStatistics[0].totalUsersWithCountCurrentYear
        }}</b>
      </p>
    </div>

    <div class="mb-4">
      <p>
        {{
          $englishPreferred
            ? "Amount of participants with at least one spontaneous count within recent 30 days"
            : "Antal deltagere med mindst en spontantælling de sidste 30 dage"
        }}:<br /><b v-if="generalStatistics[0]">{{
          generalStatistics[0].totalUsersWithCount30Days
        }}</b>
      </p>
    </div>

    <div class="mb-4">
      <p>
        {{
          $englishPreferred
            ? "Covered squares amount"
            : "Antal dækkede kvadrater"
        }}:<br /><b v-if="generalStatistics[0]">{{
          generalStatistics[0].totalAtlasSquaresCounted
        }}</b>
        {{ $englishPreferred ? "of" : "ud af" }} <b>2255</b>
      </p>
    </div>

    <div class="mb-4">
      <p>
        {{
          $englishPreferred
            ? "Covered squares amount in"
            : "Antal dækkede kvadrater I"
        }}
        {{currentYear}}:<br /><b v-if="generalStatistics[0]">{{
          generalStatistics[0].totalAtlasSquaresCountedCurrentYear
        }}</b>
        {{ $englishPreferred ? "of" : "ud af" }} <b>2255</b>
      </p>
    </div>

    <div class="mb-4">
      <p>
        {{
          $englishPreferred
            ? "Covered squares amount within recent 30 days"
            : "Antal dækkede kvadrater de sidste 30 dage"
        }}:<br /><b v-if="generalStatistics[0]">{{
          generalStatistics[0].totalAtlasSquaresCounted30Days
        }}</b>
        {{ $englishPreferred ? "of" : "ud af" }} <b>2255</b>
      </p>
    </div>

    <div class="mb-4">
      {{
        $englishPreferred
          ? "Most observed species total"
          : "Mest indtastede arter I alt"
      }}
      (top 5):<br />
      <ol>
        <li v-for="totalSpecie in totalSpecies" :key="totalSpecie.id">
          {{
            $englishPreferred ? totalSpecie.englishName : totalSpecie.danishName
          }}
        </li>
      </ol>
    </div>

    <div class="mb-4">
      {{
        $englishPreferred
          ? "Most observed species in"
          : "Mest indtastede arter I"
      }}
      {{currentYear}} (top 5):<br />
      <ol>
        <li
          v-for="totalSpecieC in totalSpeciesCurrentYear"
          :key="totalSpecieC.id"
        >
          {{
            $englishPreferred
              ? totalSpecieC.englishName
              : totalSpecieC.danishName
          }}
        </li>
      </ol>
    </div>

    <div>
      {{
        $englishPreferred
          ? "Most observed species within recent 30 days"
          : "Mest indtastede arter de sidste 30 dage"
      }}
      (top 5):<br />
      <ol>
        <li v-for="totalSpecie in totalSpecies30Days" :key="totalSpecie.id">
          {{
            $englishPreferred ? totalSpecie.englishName : totalSpecie.danishName
          }}
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
import NavHeader from "@/components/navbars/NavHeader.vue";
import generalAtlasSquareStatisticRepository from "../idb/repositories/generalAtlasSquareStatisticRepository";
import specieRankingRepository from "../idb/repositories/SpecieRankingRepository";
import specieRepository from "../idb/repositories/specieRepository";
import axios from "axios";

export default {
  components: {
    NavHeader,
  },
  data() {
    return {
      title: this.$englishPreferred
        ? "General project statistics"
        : "Generel projektstatistik",
      speciesRankings: [],
      generalStatistics: [],
      species: [],
      totalSpecies: [],
      totalSpeciesCurrentYear: [],
      totalSpecies30Days: [],
      currentYear: new Date().getFullYear(),
    };
  },
  methods: {
    async getSpecieRankingsApi() {
      return axios.get("/spontan/statistics/general/species");
    },
    async getSpecieRankings() {
      await this.getSpecieRankingsApi()
        .then((response) => {
          this.speciesRankings = response.data;
        })
        .catch(() => {
          this.online = false;
        });
      await this.getSpecies();
      this.fillSpeciesRankings();
    },
    fillSpeciesRankings() {
      this.speciesRankings.forEach((speciesRanking) => {
        var totalResultSpecie = this.species.filter((specie) => {
          return specie.speciesNumber == speciesRanking.totalSpecieNumber;
        });
        if (totalResultSpecie.length > 0) {
          this.totalSpecies.push(totalResultSpecie[0]);
        }

        var totalResultSpecieCurrentYear = this.species.filter((specie) => {
          return (
            specie.speciesNumber == speciesRanking.totalCurrentYearSpecieNumber
          );
        });
        if (totalResultSpecieCurrentYear.length > 0) {
          this.totalSpeciesCurrentYear.push(totalResultSpecieCurrentYear[0]);
        }

        var totalResultSpecie30Days = this.species.filter((specie) => {
          return specie.speciesNumber == speciesRanking.total30DaysSpecieNumber;
        });
        if (totalResultSpecie30Days.length > 0) {
          this.totalSpecies30Days.push(totalResultSpecie30Days[0]);
        }
      });
    },
    async getGeneralAtlasSquareStatisticsApi() {
      return axios.get("/spontan/statistics/general");
    },
    async getGeneralAtlasSquareStatistics() {
      await this.getGeneralAtlasSquareStatisticsApi()
        .then((response) => {
          this.generalStatistics = response.data;
        })
        .catch(() => {
          this.online = false;
        });
    },
    async getSpecies() {
      this.species = await specieRepository.getSpecies();
    },
  },
  async mounted() {
    console.time("preparing data");
    // Attempt to retrieve data directly from API, otherwise use local
    this.getSpecieRankings();
    this.getGeneralAtlasSquareStatistics();

    console.timeEnd("preparing data");
  },
};
</script>

<style scoped>
#statisticsDiv {
  overflow: hidden;
}
</style>
